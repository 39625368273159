'use client';

// react + nextjs import
import React, { useEffect } from 'react';
import Link from 'next/link';
import Image from 'next/image';
// material-ui imports
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import HomeIcon from '@mui/icons-material/Home';
import { enCodeEventLink } from '@/utils';

// icons + assets import
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import VOHLogo from '@/assets/logo_nav.png';
// custom import
import { drawerMaxWidth, drawerWidth } from '@/constants/nav';
// css import
import classes from './app-bar.module.scss';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { getAPICall } from '@/utils/network';

interface Props {
    appBarData: [
        {
            id: string;
            name: string;
            href: string;
            target: string;
            children?: {
                primary?: Array<{ name: string; href: string; type: string; target: string }>;
                secondary?: Array<{ name: string; href: string; type: string; target: string }>;
            };
        },
    ];
}

interface eventDataType {
    eventName: string;
    onlineMeetingLink: string;
}
export default function VOHAppBar({ appBarData }: Props) {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [eventData, setEventData] = React.useState<eventDataType[]>([]);
    const [eventError, setEventError] = React.useState('');
    const [mobileOpen, setMobileOpen] = React.useState(false);

    useEffect(() => {
        (async function () {
            try {
                const [data, error] = await getAPICall(`/events/pinned?limit=3`);
                if (error instanceof Error) {
                    throw new Error(error.message); // Throw an error if API call returned an error
                }
                setEventData([...data.data]); // Update state with fetched data
            } catch (error) {
                if (error instanceof Error) {
                    setEventError(error.message); // Set error state if there's an error
                } else {
                    setEventError('An unknown error occurred.'); // Fallback error message
                }
            }
        })();
    }, []);

    // console.log(eventData())
    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };

    const drawer = (
        <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
            <Link href={'/'} className="flex justify-center">
                <Image src={VOHLogo} alt="VOH Logo" width={180} priority />
            </Link>

            <Divider />
            <div className="text-left ml-6 mt-2 w-full">
                <Link href={'/'}>
                    <HomeIcon fontSize="large" />
                </Link>
            </div>

            <List>
                {appBarData.map((item, idx) => (
                    <div key={idx}>
                        <ListItemButton component={Link} href={item.href} target={item.target} sx={{ paddingBlock: 0 }}>
                            <ListItemText
                                primary={
                                    <Typography fontWeight={600} variant="h6">
                                        {item.name}
                                    </Typography>
                                }
                            />
                        </ListItemButton>
                        <List component="div" disablePadding>
                            {Array.isArray(item.children?.primary) &&
                                item.children.primary.map((child, idx) => (
                                    <ListItemButton
                                        sx={{ pl: 4, paddingBlock: 0 }}
                                        key={idx}
                                        component={Link}
                                        href={child.href}
                                        target={child.target}
                                    >
                                        <ListItemText
                                            primary={
                                                <Typography variant="body1" fontWeight={500} lineHeight={1}>
                                                    {child.name}
                                                </Typography>
                                            }
                                        />
                                    </ListItemButton>
                                ))}
                            {Array.isArray(item.children?.secondary) &&
                                item.children.secondary.map((child, idx) => (
                                    <ListItemButton
                                        sx={{ pl: 4, paddingBlock: 0 }}
                                        key={idx}
                                        component={Link}
                                        href={child.href}
                                        target={child.target}
                                    >
                                        <ListItemText
                                            primary={
                                                <Typography variant="body1" fontWeight={500}>
                                                    {child.name}
                                                </Typography>
                                            }
                                        />
                                    </ListItemButton>
                                ))}
                        </List>
                    </div>
                ))}
            </List>
        </Box>
    );

    return (
        <>
            <CssBaseline />
            <AppBar component="nav" position="sticky" color="default">
                <Toolbar sx={{ display: 'flex' }}>
                    <div className="max-[900px]:flex max-[900px]:w-full ">
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            onClick={handleDrawerToggle}
                            sx={{ mr: 2, display: { md: 'none' } }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <div className="max-[900px]:justify-center max-[900px]:flex min-[900px]:hidden max-[900px]:w-full max-[900px]:mr-12 ">
                            <Image src={VOHLogo} alt="VOH Logo" width={100} height={4} priority />
                        </div>
                    </div>

                    <Link href={'/'}>{!isSmallScreen && <HomeIcon fontSize="large" />}</Link>

                    <Box sx={{ display: { xs: 'none', md: 'flex', gap: '20px', marginInline: 'auto' } }}>
                        {appBarData.map((item, idx) => {
                            const isSecondary =
                                Array.isArray(item.children?.secondary) && item.children.secondary.length > 0;
                            return (
                                <div key={idx} className="flex items-center gap-10">
                                    {idx === 2 ? (
                                        <Image src={VOHLogo} alt="VOH Logo" width={140} height={10} priority />
                                    ) : null}
                                    <div className={classes.dropdown}>
                                        <Button
                                            key={item.name}
                                            variant="text"
                                            className={classes.dropdown}
                                            component={Link}
                                            href={item.href}
                                            target={item.target}
                                        >
                                            <Typography
                                                variant="button"
                                                fontSize={17}
                                                fontWeight={500}
                                                className="text-black"
                                            >
                                                {item.name}
                                            </Typography>
                                            {item.children && <KeyboardArrowDownIcon sx={{ color: 'black' }} />}
                                        </Button>
                                        <div className={classes.dropdownContent}>
                                            <div className={`${classes.leftGrid} ${!isSecondary && 'col-span-2'}`}>
                                                {Array.isArray(item.children?.primary) &&
                                                    item.children.primary.map((child, idx) => {
                                                        return (
                                                            <Link href={child.href} target={child.target} key={idx}>
                                                                <p>{child.name}</p>
                                                            </Link>
                                                        );
                                                    })}
                                            </div>
                                            <div className={classes.rightGrid}>
                                                {/* {Array.isArray(item.children?.secondary) &&
                                                    item.children.secondary.map((child, idx) => {
                                                        
                                                        return (
                                                            <Link href={child.href} target={child.target} key={idx}>
                                                                {child.name}
                                                            </Link>
                                                        );
                                                    })} */}
                                                {Array.isArray(eventData) && eventData.length > 0 && idx === 0
                                                    ? eventData.map((event: any, idx) => {
                                                          return (
                                                              <Link
                                                                  href={`${enCodeEventLink(
                                                                      event?.eventName,
                                                                      event?.eventId,
                                                                  )}`}
                                                                  target="_top"
                                                                  key={idx}
                                                              >
                                                                  <p>{event.eventName}</p>
                                                              </Link>
                                                          );
                                                      })
                                                    : eventError}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </Box>
                </Toolbar>
            </AppBar>
            <nav>
                <Drawer
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true,
                    }}
                    sx={{
                        display: { xs: 'block', md: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, maxWidth: drawerMaxWidth },
                    }}
                >
                    {drawer}
                </Drawer>
            </nav>
        </>
    );
}
