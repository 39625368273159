'use client';

import { getAPICall, postAPICall } from '@/utils/network';
import ReactDOM from 'react-dom';

import React, { useEffect, useState } from 'react';
import Select, { MultiValue } from 'react-select';

export default function CollaborateUs() {
    const [isOpen, setIsOpen] = useState(false);
    const [collaborationTypes, setCollaborationTypes] = useState<{ value: string; label: string }[]>([]);
    const [collaborationData, setCollaborationData] = useState({
        name: '',
        email: '',
        mobileNo: '',
        designation: '',
        companyName: '',
        collaborationType: [] as string[],
        companyAddress: '',
    });

    const openModal = () => setIsOpen(true);
    const closeModal = () => setIsOpen(false);

    const fetchCollaborationTypes = async () => {
        try {
            const [response, error] = await getAPICall('/collaborations/types');

            if (error) {
                console.error('Error fetching collaboration types:', error);
            } else {
                if (response && response.data && Array.isArray(response.data)) {
                    const collaborationType = response.data.map((item: { type: string; _id: string }) => ({
                        value: item._id,
                        label: item.type,
                    }));
                    setCollaborationTypes(collaborationType);
                } else {
                    console.error('Invalid response format for collaboration types');
                }
            }
        } catch (error) {
            console.error('Error fetching collaboration types:', error);
        }
    };

    useEffect(() => {
        fetchCollaborationTypes();
    }, []);

    const handleChangeCollaborationType = (newValue: MultiValue<{ value: string; label: string }>) => {
        setCollaborationData(prevData => ({
            ...prevData,
            collaborationType: newValue.map(option => option.value)
        }));
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setCollaborationData({ ...collaborationData, [name]: value });
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        try {
            const response = await postAPICall('/collaborations', collaborationData, 'application/json');

            if (response.status === 200) {
                alert('Form data stored successfully');
            } else {
                console.error('Error storing form data');
                alert('Error storing form data');
            }
        } catch (error) {
            console.error('Error:', error);
            alert('Error storing form data');
        }

        closeModal();
    };

    return (
        <div className="bg-cyan-700 flex flex-col md:flex-row justify-between items-center gap-x-10 gap-y-4 min-h-25 lg:min-h-20 mt-10 px-10 py-2 shadow-lg mb-4">
            <h2 className="text-white font-semibold text-lg">Unlock the power of Synergy</h2>
            <button onClick={openModal} className="border text-white px-5 py-3 font-semibold">
                Collaborate with us
            </button>

            {isOpen &&
                ReactDOM.createPortal(
                    <div className="fixed inset-0 bg-gray-600 mt-12 bg-opacity-50 flex justify-center items-center z-50">
                        <div className="bg-white rounded-lg p-6 w-full max-w-4xl mx-auto">
                            <h3 className="text-2xl font-semibold mb-4 text-center">Collaborate with Us</h3>
                            <form onSubmit={handleSubmit} className="space-y-4">
                                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700">Name</label>
                                        <input
                                            type="text"
                                            name="name"
                                            value={collaborationData.name}
                                            onChange={handleInputChange}
                                            className="mt-1 block w-full border-2 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-[#0a91b1]"
                                            required
                                            placeholder="Enter your name"
                                        />
                                    </div>
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700">E-mail ID</label>
                                        <input
                                            type="email"
                                            name="email"
                                            value={collaborationData.email}
                                            onChange={handleInputChange}
                                            className="mt-1 block w-full border-2 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-[#0a91b1]"
                                            required
                                            placeholder="Enter your email"
                                        />
                                    </div>
                                </div>
                                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700">Mobile No</label>
                                        <input
                                            type="tel"
                                            name="mobileNo"
                                            value={collaborationData.mobileNo}
                                            onChange={handleInputChange}
                                            className="mt-1 block w-full border-2 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-[#0a91b1]"
                                            required
                                            placeholder="Enter your mobile number"
                                            pattern="^[0-9]{10}$"
                                            inputMode="numeric"
                                        />
                                    </div>
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700">Designation</label>
                                        <input
                                            type="text"
                                            name="designation"
                                            value={collaborationData.designation}
                                            onChange={handleInputChange}
                                            className="mt-1 block w-full border-2 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-[#0a91b1]"
                                            required
                                            placeholder="Enter your designation"
                                        />
                                    </div>
                                </div>
                                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700">Company Name</label>
                                        <input
                                            type="text"
                                            name="companyName"
                                            value={collaborationData.companyName}
                                            onChange={handleInputChange}
                                            className="mt-1 block w-full border-2 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-[#0a91b1]"
                                            required
                                            placeholder="Enter your company name"
                                        />
                                    </div>
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700">
                                            Collaboration Type
                                        </label>
                                        <Select
                                            name="collaborationType"
                                            closeMenuOnSelect={false}
                                            isMulti
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            options={collaborationTypes}
                                            onChange={handleChangeCollaborationType}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Company Address</label>
                                    <input
                                        name="companyAddress"
                                        value={collaborationData.companyAddress}
                                        onChange={handleInputChange}
                                        className="mt-1 block w-full border-2 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-[#0a91b1]"
                                        required
                                        placeholder="Enter your company address"
                                    />
                                </div>
                                <div className="flex justify-end space-x-4">
                                    <button
                                        type="button"
                                        onClick={closeModal}
                                        className="py-2 px-4 bg-gray-500 text-white rounded-md shadow-sm hover:bg-gray-700 focus:outline-none"
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        type="submit"
                                        className="py-2 px-4 bg-blue-600 text-white rounded-md shadow-sm hover:bg-blue-700 focus:outline-none"
                                    >
                                        Submit
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>,
                    document.body
                )}
        </div>
    );
}


// // original code
// 'use client';

// import { getAPICall, postAPICall } from '@/utils/network';
// import ReactDOM from 'react-dom';

// import React, { useEffect, useState } from 'react';
// import Select, { MultiValue } from 'react-select';

// export default function CollaborateUs() {
//     const [isOpen, setIsOpen] = useState(false);
//     const [collaborationTypes, setCollaborationTypes] = useState<string[]>([]);
//     const [collaborationData, setCollaborationData] = useState({
//         name: '',
//         email: '',
//         mobileNo: '',
//         designation: '',
//         companyName: '',
//         collaborationType: [] as string[],
//         companyAddress: '',
//     });

//     const openModal = () => setIsOpen(true);
//     const closeModal = () => setIsOpen(false);

//     const fetchCollaborationTypes = async () => {
//         try {
//             const [response, error] = await getAPICall('/collaborations/types');
//             // console.log('API response data:', response); // Log the API response

//             if (error) {
//                 console.error('Error fetching collaboration types:', error);
//             } else {
//                 // Check if the response has a 'data' property
//                 if (response && response.data && Array.isArray(response.data)) {
//                     const collaborationType = response.data.map((item: { type: string; _id: string }) => ({
//                         ...collaborationTypes,
//                         value: item._id,
//                         label: item.type,
//                     }));
//                     setCollaborationTypes(collaborationType);
//                     // console.log('collaborationTypes state:', collaborationTypes); // Log the updated state
//                 } else {
//                     console.error('Invalid response format for collaboration types');
//                 }
//             }
//         } catch (error) {
//             console.error('Error fetching collaboration types:', error);
//         }
//     };

//     useEffect(() => {
//         fetchCollaborationTypes();
//     }, []);

//     // const handleChangeCollaborationType = (selectedOptions: { value: string; label: string }[]) => {
//     //     // This function will be called whenever the selection changes
//     //     // console.log('Selected options:', selectedOptions);
//     //     collaborationData.collaborationType = selectedOptions.map((option) => option.value);
//     //     // console.log('collaborationData:', collaborationData);
//     // };

//     // ...

//     const handleChangeCollaborationType = (newValue: MultiValue<{ value: string; label: string }>) => {
//         collaborationData.collaborationType = newValue.map((option) => option.value);
//     };

//     const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
//         const { name, value } = event.target;
//         setCollaborationData({ ...collaborationData, [name]: value });
//     };

//     const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
//         event.preventDefault();
//         // console.log('Handling form submission');

//         try {
//             // console.log('Sending data to server:', collaborationData);
//             const response = await postAPICall('/collaborations', collaborationData, 'application/json');

//             if (response.status === 200) {
//                 alert('Form data stored successfully');
//             } else {
//                 console.error('Error storing form data');
//                 alert('Error storing form data');
//             }
//         } catch (error) {
//             console.error('Error:', error);
//             alert('Error storing form data');
//         }

//         closeModal();
//     };

//     return (
//         <div className="bg-cyan-700 flex flex-col md:flex-row justify-between items-center gap-x-10 gap-y-4 min-h-20 mt-10 px-10 py-2 shadow-lg mb-4">
//             <h2 className="text-white font-semibold text-lg">Unlock the power of Synergy</h2>
//             <button onClick={openModal} className="border text-white px-5 py-3 font-semibold">
//                 Collaborate with us
//             </button>

//             {isOpen &&
//                 ReactDOM.createPortal(
//                     <div className="fixed inset-0 bg-gray-600 mt-12 bg-opacity-50 flex justify-center items-center z-50">
//                         <div className="bg-white rounded-lg p-6 w-full max-w-4xl mx-auto">
//                             <h3 className="text-2xl font-semibold mb-4 text-center">Collaborate with Us</h3>
//                             <form onSubmit={handleSubmit} className="space-y-4">
//                                 <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
//                                     <div>
//                                         <label className="block text-sm font-medium text-gray-700">Name</label>
//                                         <input
//                                             type="text"
//                                             name="name"
//                                             value={collaborationData.name}
//                                             onChange={handleInputChange}
//                                             className="mt-1 block w-full border-2 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-[#0a91b1]"
//                                             required
//                                             placeholder="Enter your name"
//                                         />
//                                     </div>
//                                     <div>
//                                         <label className="block text-sm font-medium text-gray-700">E-mail ID</label>
//                                         <input
//                                             type="email"
//                                             name="email"
//                                             value={collaborationData.email}
//                                             onChange={handleInputChange}
//                                             className="mt-1 block w-full border-2 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-[#0a91b1]"
//                                             required
//                                             placeholder="Enter your email"
//                                         />
//                                     </div>
//                                 </div>
//                                 <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
//                                     <div>
//                                         <label className="block text-sm font-medium text-gray-700">Mobile No</label>
//                                         <input
//                                             type="tel"
//                                             name="mobileNo"
//                                             value={collaborationData.mobileNo}
//                                             onChange={handleInputChange}
//                                             className="mt-1 block w-full border-2 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-[#0a91b1]"
//                                             required
//                                             placeholder="Enter your mobile number"
//                                             pattern="^[0-9]{10}$"
//                                             inputMode="numeric"
//                                         />
//                                     </div>
//                                     <div>
//                                         <label className="block text-sm font-medium text-gray-700">Designation</label>
//                                         <input
//                                             type="text"
//                                             name="designation"
//                                             value={collaborationData.designation}
//                                             onChange={handleInputChange}
//                                             className="mt-1 block w-full border-2 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-[#0a91b1]"
//                                             required
//                                             placeholder="Enter your designation"
//                                         />
//                                     </div>
//                                 </div>
//                                 <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
//                                     <div>
//                                         <label className="block text-sm font-medium text-gray-700">Company Name</label>
//                                         <input
//                                             type="text"
//                                             name="companyName"
//                                             value={collaborationData.companyName}
//                                             onChange={handleInputChange}
//                                             className="mt-1 block w-full border-2 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-[#0a91b1]"
//                                             required
//                                             placeholder="Enter your company name"
//                                         />
//                                     </div>
//                                     <div>
//                                         <label className="block text-sm font-medium text-gray-700">
//                                             Collaboration Type
//                                         </label>
//                                         <Select
//                                             name="collaborationType"
//                                             closeMenuOnSelect={false}
//                                             isMulti
//                                             className="basic-multi-select"
//                                             classNamePrefix="select"
//                                             options={collaborationTypes}
//                                             onChange={handleChangeCollaborationType}
//                                         />
//                                     </div>
//                                 </div>
//                                 <div>
//                                     <label className="block text-sm font-medium text-gray-700">Company Address</label>
//                                     <input
//                                         name="companyAddress"
//                                         value={collaborationData.companyAddress}
//                                         onChange={handleInputChange}
//                                         className="mt-1 block w-full border-2 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-[#0a91b1]"
//                                         required
//                                         placeholder="Enter your company address"
//                                         // rows={4}
//                                     />
//                                 </div>
//                                 <div className="flex justify-end space-x-4">
//                                     <button
//                                         type="button"
//                                         onClick={closeModal}
//                                         className="py-2 px-4 bg-gray-500 text-white rounded-md shadow-sm hover:bg-gray-700 focus:outline-none"
//                                     >
//                                         Cancel
//                                     </button>
//                                     <button
//                                         type="submit"
//                                         className="py-2 px-4 bg-blue-600 text-white rounded-md shadow-sm hover:bg-blue-700 focus:outline-none"
//                                     >
//                                         Submit
//                                     </button>
//                                 </div>
//                             </form>
//                         </div>
//                     </div>,
//                     document.body,
//                 )}
//         </div>
//     );
// }
